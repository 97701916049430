<template>
  <b-overlay
    :show="cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-card>
      <b-row class="mt-0">

        <!-- CURSO -->
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group
            label="Curso *"
            label-for="curso"
          >
            <b-overlay
              :show="cargandoCursos"
              spinner-variant="primary"
              variant="semi-dark"
            >
              <v-select
                input-id="id_curso"
                v-model="reunion.id_curso"
                placeholder="Selecciona un curso..."
                label="texto"
                :options="optionsCursos"
                :reduce="option => option.id"
                :clearable="false"
                :disabled="typeof this.reunion.id !== 'undefined'"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :class="v$.reunion.id_curso.$error === true
                  ? 'border-danger rounded'
                  : ''"
              />
            </b-overlay>
            <div
              v-if="v$.reunion.id_curso.$error"
              id="id_cursoInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.reunion.id_curso.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </b-form-group>
        </b-col>

        <!-- FECHA -->
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group
            label="Fecha *"
            label-for="fecha"
          >
            <calendario
              id="fecha"
              tipo="hastaFechaFinal"
              :fecha="reunion.fecha"
              :state="v$.reunion.fecha.$error"
              @changeCalendario="changeCalendario"
            />
              <!-- Mensajes Error Validación -->
              <b-form-invalid-feedback
                v-if="v$.reunion.fecha.$error"
                id="fechaInfo"
                class="text-right"
              >
                <p v-for="error of v$.reunion.fecha.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <!-- HORA -->
        <b-col
          cols="12"
          lg="4"
        >
          <b-form-group
            label="Hora *"
            label-for="hora"
          >
            <hora
              id="hora"
              :showBtn="true"
              :hora.sync="reunion.hora"
              :state="v$.reunion.hora.$error"
              @changeHora="changeHora"
            />

            <!-- Mensajes Error Validación -->
            <div
              v-if="v$.reunion.hora.$error"
              id="horaInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.reunion.hora.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </b-form-group>
        </b-col>

        <!-- ACUERDOS -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Acuerdos"
            label-for="acuerdos"
          >
            <b-form-textarea
              id="acuerdos"
              placeholder="Ingresa los acuerdos de la reunión"
              v-model="reunion.acuerdos"
            />
          </b-form-group>
        </b-col>

        <!-- OBSERVACIÓN -->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="Observaciones"
            label-for="observacion"
          >
            <b-form-textarea
              id="observacion"
              placeholder="Ingresa las observaciones de la reunión"
              v-model="reunion.observaciones"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <!-- ASISTENCIA -->
      <b-form-group
        v-if="reunion.id_curso"
        label="Asistencias"
        label-for="asistencia"
        class="mb-1 mt-1"
      >
        <reuniones-asistencias
          :id_curso.sync="reunion.id_curso"
          :reunion.sync="reunion"
          :alumnos="reunion.alumnos"
        />
      </b-form-group>

      <!-- FOOTER BTNS -->
      <b-row>
        <b-col
          cols="12"
          md="10"
        >
        </b-col>
        <b-col
          cols="12"
          md="2"
        >
          <btnSubmit
            class="float-right"
            variant="primary"
            :btnText="btnSubmit"
            :modulo="nombre_permiso"
            @processBtn="submitOption"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BFormDatepicker, BFormTimepicker,
  BFormTextarea, BAlert, BModal, BCard, BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

// AXIOS
import { mapGetters, mapActions } from 'vuex'

// FORMATOS
import { formatos } from '@core/mixins/ui/formatos'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'

// COMPONENTES HIJOS
import ReunionesAsistencias from './ReunionesAsistencias.vue'

// COMPONENTES RECICLADOS
import calendario from '../../components/Form/calendario.vue'
import hora from '../../components/Form/hora.vue'
import btnSubmit from '../../components/Form/btnSubmit.vue'

export default {
  components: {
    // ETIQUETAS
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    BOverlay,
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    BAlert,
    BModal,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    vSelect,

    // COMPONENTES HIJOS
    ReunionesAsistencias,

    // COMPONENTES RECICLADOS
    calendario,
    hora,
    btnSubmit,
  },
  mixins: [formatos],
  validations() {
    return {
      reunion: {
        id_curso: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
        hora: {
          $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
        },
      }
    }
  },
  data() {
    return {
      time: {
        time: true,
        timePattern: ['h', 'm'],
      },
      nombre_permiso: 'reunionesApoderados',
      cargandoCursos: true,
      cargando: false,
      optionsCursos: [],
    }
  },
  computed: {
    ...mapGetters({
      getCursos: 'cursos/getCursos',
    }),
  },
  props: {
    btnSubmit: {
      type: String,
      Default: 'Guardar',
    },
    reunion: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.cargarCursosJefatura()
  },
  methods: {
    ...mapActions({
      fetchCursosJefatura: 'cursos/fetchCursosJefatura',
    }),
    cargarCursosJefatura() {
      this.fetchCursosJefatura().then(() => {
        this.setCursosJefatura()
      })
    },
    setCursosJefatura() {
      this.optionsCursos = []
      if (typeof this.reunion.id === 'undefined') { // CREAR
        this.getCursos.forEach(curso => {
          const texto = `${curso.nombre} ${curso.letra}`
          this.optionsCursos.push({
            id: curso.id,
            texto,
          })
        })

      } else { // EDITAR
        const curso = this.getCursos.find(c => c.id === this.reunion.id_curso)
        const texto = `${curso.nombre} ${curso.letra}`
        this.optionsCursos.push({
          id: curso.id,
          texto,
        })
      }
      if (this.getCursos.length === 1) {
        this.reunion.id_curso = this.getCursos[0].id
      }
      this.cargandoCursos = false
    },
    submitOption() {
      this.v$.reunion.$touch()
      if (!this.v$.reunion.$invalid) {
        let alumnos = []
        this.reunion.alumnos.forEach(alumno => {
          alumnos.push({
            id_alumno: alumno.id_persona_rol_alumno,
            id_asistencia: alumno.id_asistencia,
            asistencia: alumno.asistencia,
            firma: alumno.firma,
          })
        })
        const id = this.reunion.id ? this.reunion.id : null
        const data = {
          id,
          id_curso: this.reunion.id_curso,
          fecha: this.reunion.fecha,
          hora: this.reunion.hora,
          acuerdos: this.reunion.acuerdos,
          observaciones: this.reunion.observaciones,
          alumnos,
        }
        this.$emit('processForm', data)
      }
    },
    // CALENDARIO
    changeCalendario(fecha) {
      this.reunion.fecha = fecha
    },
    // HORA
    changeHora(hora) {
      this.reunion.hora = hora
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
