<template>
  <reunion-apoderado-form
    btnSubmit="Crear Reunión"
    :reunion.sync="data"
    @processForm="agregar"
  />
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import ReunionApoderadoForm from './ReunionApoderadoForm.vue'

export default {
  components: {
    // COMPONENTES
    ReunionApoderadoForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        id_curso: null,
        fecha: '',
        hora: '18:30',
        acuerdos: '',
        observaciones: '',
        alumnos: [],
      },
    }
  },
  methods: {
    ...mapActions({
      createReunion: 'reuniones/addReunion',
    }),
    agregar(reunion) {
      this.createReunion(reunion).then(() => {
        const statusReuniones = store.state.reuniones.status
        if (statusReuniones === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Reunión guardada 👍',
              icon: 'CheckIcon',
              text: 'La reunión fue ingresada con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })

          this.$router.replace({
            name: 'reuniones-apoderados',
          })
        } else {
          this.$swal({
            title: 'Atención!',
            text: store.state.reuniones.message,
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
